const formatCurrency = (value) => {
  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export default () => {
  const priceFields = document.querySelectorAll('.line-price-field');
  const subTotalElement = document.getElementById('sub-total');
  const salesTaxElement = document.getElementById('sales-tax');
  const salesTaxRate = salesTaxElement.dataset.rate ? parseFloat(salesTaxElement.dataset.rate) : 0;
  const totalElement = document.getElementById('total');

  let subTotal = 0;
  for (const priceField of priceFields) {
    const value = parseFloat(priceField.value);
    if (!isNaN(value)) {
      subTotal += value;
    }
  }
  const salesTax = subTotal * salesTaxRate;
  subTotalElement.innerHTML = formatCurrency(subTotal);
  salesTaxElement.innerHTML = formatCurrency(salesTax);
  totalElement.innerHTML = formatCurrency(subTotal + salesTax);
}
