import updateTotals from './update_totals';
import createElement from './create_element';

const getAttributesNameFrom = (input) => {
  return input.id
    .replace(/bid_/, '')
    .replace(/_[0-9]+_[a-z]+$/, '');
}

const rewriteFieldNames = (tbody) => {
  const descriptionInputs = Array.from(tbody.querySelectorAll('input'))
    .filter(input => input.id.match(/_description/))
    .sort((a, b) => {
      let matches = a.id.match(/_([0-9]+)_/);
      const aIndex = parseInt(matches[1]);
      matches = b.id.match(/_([0-9]+)_/);
      const bIndex = parseInt(matches[1]);
      return aIndex - bIndex;
    });
  let index = 0;
  for (const descriptionInput of descriptionInputs) {
    const hiddenIdInputId = descriptionInput.id.replace('_description', '_id');
    const priceId = descriptionInput.id.replace('_description', '_price');
    const hiddenIdInput = document.getElementById(hiddenIdInputId);
    const priceInput = document.getElementById(priceId);

    rewriteIndex(descriptionInput, index);
    if (hiddenIdInput) {
      rewriteIndex(hiddenIdInput, index);
    }
    rewriteIndex(priceInput, index);
    index++;
  }
  return index;
}

const rewriteIndex = (input, index) => {
  const attributesName = getAttributesNameFrom(input);
  const matches = input.id.match(/_([a-z]+)$/);
  const attribute = matches[1];
  input.id = idAttribute(attributesName, index, attribute);
  input.name = nameAttribute(attributesName, index, attribute);
}

const idAttribute = (attributesName, index, attribute) => {
  return `bid_${attributesName}_${index}_${attribute}`;
}

const nameAttribute = (attributesName, index, attribute) => {
  return `bid[${attributesName}][${index}][${attribute}]`;
}

export default (event) => {
  const tr = event.target.closest('tr');
  const parent = tr.parentNode;
  const firstItemInput = tr.querySelectorAll('input')[0];
  const idInputId = firstItemInput.id.replace(/(description|price)/, 'id');
  const hiddenIdInput = document.getElementById(idInputId);
  tr.remove();
  const nextIndex = rewriteFieldNames(parent);
  if (hiddenIdInput) {
    rewriteIndex(hiddenIdInput, nextIndex);
    parent.appendChild(
      createElement('input', {
        'type': 'hidden',
        'id': idAttribute(getAttributesNameFrom(hiddenIdInput), nextIndex, 'destroy'),
        'name': nameAttribute(getAttributesNameFrom(hiddenIdInput), nextIndex, '_destroy'),
        'value': '1'
      })
    );
  }
  updateTotals();
}
