import updateTotals from './update_totals';
import removeItem from './remove_item';
import createElement from './create_element';

addEventListener('DOMContentLoaded', (_event) => {
  if (!document.getElementById('edit-bid')) return;

  const createRemoveIconTd = (iconClassName) => {
    return createElement('td', {}, (td) => {
      const circleXPath = document.getElementById('edit-bid').getAttribute('data-circle-x-path');
      const img = createElement('img', { 'src': circleXPath, class: `circle-x cursor-pointer ${iconClassName}` });
      img.addEventListener('click', removeItem);
      td.appendChild(img);
    })
  }

  const addAdditionEvents = (options) => {
    const bidLinesTBody = document.getElementById(options.tbodyId);
    const addNewDescription = document.getElementById(options.addByDescriptionId);
    const addNewPrice = document.getElementById(options.addByPriceId);
    const descriptionColspan = options.descriptionColspan || 1;
    const addNewLine = (event) => {
      event.preventDefault();
      const tr = createElement('tr', { 'class': 'table-tr' }, (tr) => {
        const priceFieldCount = document.querySelectorAll(`.${options.priceFieldClass}`).length;
        const descriptionTd = createElement('td', { 'class': 'py-2 px-2', 'colspan': descriptionColspan }, (td) => {
          const input = createElement('input', {
            'id': `${options.railsIdPrefix}_${priceFieldCount}_description`,
            'type': 'text',
            'class': 'w-full',
            'name': `bid[${options.railsNestedAttributesName}][${priceFieldCount}][description]`
          });
          td.appendChild(input);
          if (event.target.id == options.addByDescriptionId) {
            window.setTimeout(() => input.focus(), 0);
          }
        });
        const priceTd = createElement('td', { 'class': 'py-2 px-2' }, (td) => {
          const input = createElement('input', {
            'id': `${options.railsIdPrefix}_${priceFieldCount}_price`,
            'type': 'number',
            'class': `w-full text-right ${options.priceFieldClass}`,
            'name': `bid[${options.railsNestedAttributesName}][${priceFieldCount}][price]`
          });
          if (options.priceInputCallback) {
            options.priceInputCallback(input);
          }
          td.appendChild(input);
          if (event.target.id == options.addByPriceId) {
            window.setTimeout(() => input.focus(), 0);
          }
        });
        tr.appendChild(descriptionTd);
        tr.appendChild(priceTd);
        if (descriptionColspan == 1) {
          tr.appendChild(createElement('td', { 'class': 'py-2 px-2' }));
        }
        tr.appendChild(createRemoveIconTd(options.removeClass));
      });
      bidLinesTBody.appendChild(tr);
    }
    addNewDescription.addEventListener('mousedown', addNewLine);
    addNewPrice.addEventListener('click', addNewLine);
  }

  addAdditionEvents({
    tbodyId: 'bid-lines',
    addByDescriptionId: 'add-line-description-input',
    descriptionColspan: 2,
    addByPriceId: 'add-line-price-input',
    priceFieldClass: 'line-price-field',
    removeClass: 'remove-line',
    railsIdPrefix: 'bid_lines_attributes',
    railsNestedAttributesName: 'lines_attributes',
    priceInputCallback: (input) => {
      input.addEventListener('input', updateTotals);
    }
  });

  addAdditionEvents({
    tbodyId: 'bid-additions',
    addByDescriptionId: 'add-addition-description-input',
    addByPriceId: 'add-addition-price-input',
    priceFieldClass: 'addition-price-field',
    removeClass: 'remove-addition',
    railsIdPrefix: 'bid_additions_attributes',
    railsNestedAttributesName: 'additions_attributes',
  });
});